const BOpath = import.meta.env.VITE_BACKOFFICE_UI;

export const postMessageKeys = {
  selectType: 'SelectType',
  changeFilterDate: 'ChangeFilterDate',
  openDetailSummary: 'OpenDetailSummary',
  openDetailGroup: 'OpenDetailGroup',
  loginReport: 'LoginReport',
  logoutReport: 'LogoutReport',
  resize: 'resize',
  custom: 'custom',
  lang: 'lang',
  requestCredentials: 'requestCredentials',
};

export const senderPostMessage = (key, data) => {
  const payload = {
    key,
    data,
  };
  window.parent.postMessage(JSON.stringify(payload), BOpath);
};

export const senderPostMessageLogin = (targetRef, key, data, isOnLoad) => {
  if (targetRef) {
    const payload = {
      key,
      data,
    };

    // isOnLoad digunakan untuk postMessage ke iframe saat useEffect
    // karena ketika postmessage sewaktu useEffect, iframe belum ready
    if (isOnLoad) {
      targetRef.addEventListener('load', () => {
        targetRef.contentWindow.postMessage(
          JSON.stringify(payload),
          `${BOpath}/container`,
        );
      });
    } else {
      targetRef.contentWindow.postMessage(
        JSON.stringify(payload),
        `${BOpath}/container`,
      );
    }
  }
};

export const listenerPostMessage = (callback) => {
  window.addEventListener('message', (event) => {
    if (event.origin !== BOpath) {
      callback();
    }

    if (!event?.data?.type?.includes('webpack')) {
      // listen PM normal, jika data JSON, maka parse, jika string, masuk catch
      let result = null;
      try {
        result = JSON.parse(event.data);
      } catch (_) {
        result = event.data;
      }
      callback(result);

      import.meta.env.MODE !== 'production' &&
        senderPostMessage('success sent >> ', { iframe_received: event.data });
    }
  });
};

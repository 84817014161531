import * as Mongo from 'mongoservices/setup';

export default (error) => {
  let result = false;
  if (error.errorCode === 'InvalidSession') {
    result = true;
    Mongo.LogoutRealm();
  }
  return result;
};

import { LSname } from 'localstorage';

export const getLocalStorage = (key, raw) => {
  const item = localStorage.getItem(key);
  if (raw) {
    return item;
  }
  return item ? JSON.parse(item) : item;
};

export const getLSI18N = () => {
  return getLocalStorage(LSname.i18n, true);
};

export const getLSReportAccess = () => {
  return getLocalStorage(LSname.report_access);
};

export const getLSDisableLog = () => {
  return getLocalStorage(LSname.disable_report_log);
};

export const getLSProfile = () => {
  return getLocalStorage(LSname.profile);
};

import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'context/AuthContext';
import * as Sentry from '@sentry/react';
import { SentryInfo, SentryLevel, ignorableErrors } from 'utils/sentry';
import brandBuild from 'assets/file/brandBuild.json';
import { brandName, checkLang, cloneData } from 'functions/functions';
import ThemeStyles from 'ThemeStyles';
import Routes from './Routes';
import { listenerPostMessage, postMessageKeys } from 'functions/postMessage';
import { LogoutRealm, loginRealm } from 'mongoservices/setup';
import {
  setLSDisableLog,
  setLSI18N,
  setLSReportAccess,
} from 'localstorage/setter';
import { setCookieAccess } from 'cookies/setter';

const brandRelease = `BO_Report_${import.meta.env.MODE.toUpperCase()}@${
  brandBuild.version
}`;

const App = () => {
  const handleMessage = (result) => {
    if (
      result &&
      result?.key === postMessageKeys.loginReport &&
      result?.data?.report_access &&
      result?.data?.report_appId
    ) {
      LogoutRealm();
      loginRealm(result.data.report_access, result.data.report_appId);
      const dataToSet = cloneData(result.data);
      setLSI18N(checkLang(dataToSet.lang));
      setCookieAccess({ ...dataToSet });
      delete dataToSet.lang;
      setLSReportAccess({ ...dataToSet });
    }
    if (result && result?.key === postMessageKeys.logoutReport) {
      localStorage.clear();
    }
    if (result && result?.key === postMessageKeys.custom) {
      if (Object.keys(result?.data).includes('disable_report_log')) {
        setLSDisableLog(result?.data?.disable_report_log);
      }
    }

    if (result && result?.key === postMessageKeys.lang) {
      setLSI18N(checkLang(result.data));
    }
  };

  useEffect(() => {
    if (!document.title.includes(import.meta.env.VITE_TITLE)) {
      document.title = `${import.meta.env.VITE_TITLE} - ${brandName(true)} ${
        document.title
      }`;
    }

    if (
      !import.meta.env.MODE.toLowerCase().includes('local') &&
      !import.meta.env.MODE.toLowerCase().includes('development')
    ) {
      Sentry.init({
        dsn: 'https://a66515932e144120bd93977d542d56af@sentry.mgc.pw/7',
        release: `${brandRelease}`,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
          }),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        environment: import.meta.env.VITE_SENTRY_ENV || 'LOCAL',
        ignoreErrors: ignorableErrors,
        initialScope: (scope) => {
          scope.setTags({ errorType: 'UNHANDLED ERROR' });
          scope.setLevel(SentryLevel.Fatal);
          scope.setTags(SentryInfo().tags);
          scope.setUser(SentryInfo().user);

          return scope;
        },
      });
    }
    window.parent.postMessage('report-ready', '*');
  }, []);

  listenerPostMessage(handleMessage);

  return (
    <div className="App">
      <ThemeProvider theme={ThemeStyles}>
        <AuthProvider>
          <Router>
            <Routes />
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;

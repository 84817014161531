import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { getLSReportAccess } from 'localstorage/getter';
import { postMessageKeys, senderPostMessageLogin } from 'functions/postMessage';
const BOpath = import.meta.env.VITE_BACKOFFICE_UI;
import { LogoutRealm, loginRealm } from 'mongoservices/setup';
import { checkLang, cloneData } from 'functions/functions';
import { setLSI18N, setLSReportAccess } from 'localstorage/setter';
import { getCookieAccess } from 'cookies/getter';
import { setCookieAccess } from 'cookies/setter';

/* ===========> BACA DULU SEBELUM EDIT <===========

TOLONG JANGAN MASUKKAN RANDOM VARIABLE KE SINI, ATAU KONSULTASIKAN KE MAINTAINER BO

===========> BACA DULU SEBELUM EDIT <=========== */

const useStyles = makeStyles(() => ({
  authContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    '@media print': {
      overflowY: 'visible',
      overflowX: 'visible',
    },
  },
}));

export const AuthContext = React.createContext([{}, () => {}]);

const AuthProvider = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({});
  const _refLoginContainer = useRef(null);
  const access = getLSReportAccess();

  const setAuthState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getAuthState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const loginReport = (loginData, param) => {
    const { report_access, report_app } = loginData;
    LogoutRealm();
    loginRealm(report_access, report_app);
    const dataToSet = cloneData(loginData);
    setLSI18N(checkLang(dataToSet.lang));
    if (param === 'ls') {
      setCookieAccess({ ...dataToSet });
    }
    delete dataToSet.lang;
    setLSReportAccess({ ...dataToSet });
    window.location.reload();
  };

  const requestCredentials = useCallback(() => {
    const handleMessage = (event) => {
      if (event.data === 'login-ready') {
        senderPostMessageLogin(
          _refLoginContainer.current,
          postMessageKeys.requestCredentials,
        );
      } else if (event.data?.key === postMessageKeys.requestCredentials) {
        const resultData = event.data?.data;
        loginReport(resultData, 'ls');
      }
    };

    if (!access) {
      const retrieveCookieAccess = getCookieAccess();
      if (retrieveCookieAccess) {
        loginReport(retrieveCookieAccess, 'cookie');
      } else {
        window.addEventListener('message', handleMessage);
      }
    }
  }, [access]);

  useEffect(() => {
    requestCredentials();
  }, [requestCredentials]);

  return (
    <AuthContext.Provider
      value={{
        getAuthState,
        setAuthState,
        requestCredentials,
      }}>
      <Box
        className={classes.authContainer}
        style={{
          height: '100dvh',
          width: '100%',
        }}>
        {props.children}
      </Box>

      {!access && (
        <iframe
          ref={_refLoginContainer}
          src={`${BOpath}/container`}
          title="GENERAL IFRAME REPORT"
          style={{ display: 'none' }}
          frameBorder="none"
        />
      )}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const value = useContext(AuthContext);
  if (value == null) {
    throw new Error('useAuthContext() called outside of a PosProvider?');
  }
  return value;
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthProvider;

/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { clearCookies } from 'cookies/setter';
import { checkLang } from 'functions/functions';
import { logInfo, logPayloadResponse } from 'functions/logging';
import { getLSI18N, getLSReportAccess } from 'localstorage/getter';
import { clearLocalStorage, setLSProfile } from 'localstorage/setter';
import moment from 'moment-timezone';
import * as Realm from 'realm-web';
import errorHandler from 'utils/errorHandler';
import { SentryLevel, sentSentryError } from 'utils/sentry';

const lang = getLSI18N();

let REALM_APP_ID = '';
let app = {};

export const realmFunction = {
  clientReportBusiness: 'clientReportBusiness',
  clientReportOutlet: 'clientReportOutlet',
  clientReportSummary: 'clientReportSummary',
  clientReportGroupSales: 'clientReportGroupSales',
  clientReportDepartment: 'clientReportDepartment',
  clientReportPackage: 'clientReportPackage',
  clientReportProductSales: 'clientReportProductSales',
  clientReportPettyCash: 'clientReportPettyCash',
  clientReportVoidRefund: 'clientReportVoidRefund',
  clientReportVoid: 'clientReportVoid',
  clientReportTransactions: 'clientReportTransactions',
  clientReportTransactionDetails: 'clientReportTransactionDetails',
  clientReportCommission: 'clientReportCommission',
  clientReportStockSummary: 'clientReportStockSummary',
  clientReportStockDetail: 'clientReportStockDetail',
  clientReportStockAudit: 'clientReportStockAudit',
  clientReportDetailStockAudit: 'clientReportDetailStockAudit',
};

export const loginRealm = async (jwt_report, appId_report) => {
  await app?.currentUser?.logOut();
  const credentials = Realm.Credentials.jwt(jwt_report);
  REALM_APP_ID = appId_report;
  app = new Realm.App({ id: REALM_APP_ID, timeout: 10000 });

  try {
    // Authenticate the user
    const user = await app.logIn(credentials);
    if (user) {
      setLSProfile(user?._profile?.data);
    }
    return user;
  } catch (err) {
    sentSentryError(err, SentryLevel.Error);
    console.error('Failed to log in', err);
  }
};

export const LogoutRealm = async () => {
  if (app && app?.currentUser) {
    await app.currentUser?.logOut();
  }
  clearLocalStorage();
  clearCookies();
};

export const Call = async (
  functionName,
  method,
  filterDate,
  data = {},
  filter = {},
  useOffset = false,
) => {
  const localMethod = method || 'GET';
  const realmFilter = { ...filter };
  try {
    if (
      filterDate &&
      (filterDate.start_date || filterDate.end_date) &&
      useOffset
    ) {
      const getOffset =
        +new Date(
          moment(filterDate.start_date || filterDate.end_date, 'x')?.toDate(),
        )?.getTimezoneOffset() / 60;
      realmFilter.time_offset = getOffset;

      realmFilter.start_date =
        new Date(+filterDate.start_date).toString() || '';
      realmFilter.end_date = new Date(+filterDate.end_date).toString() || '';
    }

    const payload = {
      method: localMethod,
      headers: {
        Lang: checkLang(lang),
      },
      data,
      filter: realmFilter,
    };

    const jwt_access = getLSReportAccess();
    let realmUser = app?.currentUser;
    if (!realmUser) {
      realmUser = await loginRealm(
        jwt_access.report_access,
        jwt_access.report_appId,
      );
    }

    logInfo(realmUser?.app?.id);
    const hitServer = await realmUser?.callFunction(functionName, payload);
    logPayloadResponse(functionName, payload, hitServer, 'success');

    return hitServer;
  } catch (err) {
    if (!errorHandler(err)) {
      logPayloadResponse(
        functionName,
        {
          method: localMethod,
          headers: {
          Lang: checkLang(lang),
        },
          data,
          filter: realmFilter,
        },
        null,
        'error',
      );
      sentSentryError(err, SentryLevel.Error);
    }

    return { error: true, message: err };
  }
};

import { getLSReportAccess } from "localstorage/getter";
import { clearLocalStorage, setLSReportAccess } from "localstorage/setter";
import { isDecimal, validatePositiveNumber } from "./validation";

export const reportAccessSetter = (newAccess) => {
  if (newAccess) {
    const currentAccess = getLSReportAccess();
    if (currentAccess !== newAccess) {
      clearLocalStorage();
      setLSReportAccess(newAccess);
    }
  }
};

export const checkLang = (lang) => {
  if(!lang || lang === 'undefined'){
    return 'id'
  }
  return lang
}

export const convertNumber = (number) => {
  const localNumber = (n) => {
    let result = n;
    if (typeof n === "string") {
      if (validatePositiveNumber(n)) {
        result = n;
      } else {
        result = +n;
      }
    }
    return result;
  };

  const locale = "id-ID";
  const num = number || 0;
  // jika pengen ada koma di belakang angka contoh 10.000,02 maka pakai code dibawah ini
  // number.toLocaleString('id-ID', { minimumFractionDigits: 2 });
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  return localNumber(num).toLocaleString(
    locale,
    isDecimal(localNumber(num)) ? options : undefined
  );
};

export const minusToBeBrackets = (number) => {
  const localNumber = number.toString();
  if (localNumber.includes("-")) {
    return localNumber.replace("-", "(").concat(")");
  }
  return number;
};

export const capitalizeFirstString = (string) => {
  return string && string[0].toUpperCase() + string.slice(1);
};

// GET QUERY STRING FROM THE URL ex: /verify?name=john => ?name=john
export const getLocationQuery = (location, parameter) => {
  if (parameter) {
    return new URLSearchParams(location.search).get(parameter);
  }
  return location.search;
};

export const convertNumberToMonthName = (number) => {
  const date = new Date();
  date.setMonth(+number);

  return date.toLocaleString("en-US", {
    month: "long",
  });
};

export const getFilterMinMax = (paramData, minValue, maxValue, field) => {
  const localData = Array.isArray(paramData) ? paramData : paramData?.data;

  if (localData?.length > 0) {
    return localData.filter((item) => {
      let result = item;
      if (minValue && maxValue) {
        result = item?.[field] >= minValue && item?.[field] <= maxValue;
      } else if (minValue) {
        result = item?.[field] >= minValue;
      } else if (maxValue) {
        result = item?.[field] <= maxValue;
      }
      return result;
    });
  }
};

export const sumTotalArray = (paramData, key) => {
  if (Array.isArray(paramData)) {
    return paramData.reduce((acc, curr) => {
      return +curr[key] + +acc;
    }, 0);
  }

  return null;
};

export const reCalculateRowTotal = (data, field) => {
  // data = data server, field = array of key
  const resultTotal = {};
  field.forEach((item) => {
    resultTotal[item] = sumTotalArray(data, item);
  });

  return resultTotal;
};

export const milisecToSec = (value) => {
  return (value / 1000).toFixed(2);
};

export const cloneData = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const brandName = (isNotFullText) => {
  return isNotFullText ? "Grande" : "Grande POS";
};

export const allFirst = (a, b) => {
  if (a.key === "all") {
    return -1;
  }
  if (b.key === "all") {
    return 1;
  }
  return 0;
};

export const _ISNOTPRODUCTION = () => {
  return (
    import.meta.env.MODE.toLowerCase() !== "production" &&
    import.meta.env.MODE.toLowerCase() !== "beta"
  );
};

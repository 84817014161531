export const isDecimal = (value) => {
  const regex = /^[-+]?\d+\.\d+$/;
  return regex.test(value);
};

export const validatePositiveNumber = (value) => {
  // regex hanya positive number
  // const regex = /^[0-9]\d*$/;

  // regex positive number + decimal
  const regex = /^[+]?(\d+(?:\.\d*)?|\.\d+)$/;
  return regex.test(value);
};

import { CookieName } from 'cookies';
import Cookies from 'js-cookie';

export const setCookie = (key, value) => {
  Cookies.set(key, JSON.stringify(value));
};

export const setCookieAccess = (data) => {
  setCookie(CookieName.report_crd, data);
};

export const clearCookies = () => {
  const allCookies = Cookies.get();

  Object.keys(allCookies).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
};

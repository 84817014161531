import * as Sentry from '@sentry/react';
import { getLSProfile } from 'localstorage/getter';

export const SentryLevel = {
  Fatal: 'fatal',
  Critical: 'critical',
  Error: 'error',
  Warning: 'warning',
  Log: 'log',
  Info: 'info',
  Debug: 'debug',
};

export const SentryInfo = () => {
  const credential = getLSProfile();

  const tagsInfo = {
    user_id: credential?.user_id,
  };
  const userInfo = {
    user_id: credential?.user_id,
    name: credential?.name,
    license: credential?.license,
  };

  return { tags: tagsInfo, user: userInfo };
};

export const sentSentryError = (error, level = SentryLevel.Info) => {
  if (
    !import.meta.env.MODE.toLowerCase().includes('local') &&
    !import.meta.env.MODE.toLowerCase().includes('development')
  ) {
    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setUser(SentryInfo().user);
      scope.setTags(SentryInfo().tags);

      // The exception has the event level set by the scope (info).
      Sentry.captureException(
        error ||
          new Error(
            'This is Default Error cause Property Error Message is not passed',
          ),
      );
    });
  }
};

export const ignorableErrors = [
  'Failed to fetch', // biasanya gagal hit realm
  'Request failed', // biasanya gagal hit realm
  'Maximum update depth exceeded', // terlalu dalam untuk disolve (tidak major mempengaruhi app)
];

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const ListPath = (props) => {
  return (
    <Box>
      <h2>LIST PATH YANG BISA DI PAKAI :</h2>
      <ol style={{ display: 'inline-block', margin: '0 auto' }}>
        {props.paths.map((route) => (
          <li key={route.path}>{route.path}</li>
        ))}
      </ol>
    </Box>
  );
};

ListPath.propTypes = {
  paths: PropTypes.array,
};

export default ListPath;

/* eslint-disable react-refresh/only-export-components */
import { Suspense, lazy } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import ReSimpleCircularProgress from 'components/ReSimpleCircularProgress';
import ListPath from './pages/ListPath';

const GeneralPage = lazy(() => import('./pages/GeneralPage'));
const SalesSummaryPage = lazy(() =>
  import('./pages/SalesSummary/ReportDashboard'),
);
const DashboardStepsPage = lazy(() =>
  import('./pages/SalesSummary/mobileComponent/DashboardStepsPage'),
);
const DetailSummaryPage = lazy(() => import('./pages/details/DetailSummary'));
const DetailSalesGroupPage = lazy(() =>
  import('./pages/details/DetailSalesGroupPage'),
);
const DetailSalesDepartmentPage = lazy(() =>
  import('./pages/details/DetailSalesDepartmentPage'),
);
const DetailSalesPackagePage = lazy(() =>
  import('./pages/details/DetailSalesPackagePage'),
);
const DetailSalesProductPage = lazy(() =>
  import('./pages/details/DetailSalesProductPage'),
);
const DetailSalesPettyCash = lazy(() =>
  import('./pages/details/DetailSalesPettyCash'),
);
const RefundVoidSummary = lazy(() =>
  import('./pages/details/RefundVoidSummary'),
);
const RefundVoidDetails = lazy(() =>
  import('./pages/details/RefundVoidDetails'),
);
const DetailVoidReport = lazy(() => import('./pages/details/DetailVoidReport'));
const TransactionListPage = lazy(() =>
  import('./pages/details/TransactionListPage'),
);
const TransactionDetailPage = lazy(() =>
  import('./pages/details/TransactionDetailPage'),
);
const StockSummaryPage = lazy(() => import('./pages/details/StockSummaryPage'));
const StockTransactionPage = lazy(() =>
  import('./pages/details/StockTransactionPage'),
);
const StockAuditListPage = lazy(() =>
  import('./pages/details/StockAuditListPage'),
);
const StockAuditDetailPage = lazy(() =>
  import('./pages/details/StockAuditDetailPage'),
);

const CommissionSummary = lazy(() =>
  import('./pages/details/CommissionSummary'),
);

const CommissionDetails = lazy(() =>
  import('./pages/details/CommissionDetailsPage'),
);

const SendPostMessage = lazy(() => import('./pages/DevTools/SendPostMessage'));
const ListenPostMessage = lazy(() =>
  import('./pages/DevTools/ListenPostMessage'),
);
const DummyFetch = lazy(() => import('./pages/DevTools/DummyFetch'));
const DeployInfo = lazy(() => import('./pages/DevTools/DeployInfo'));

export const RoutePaths = [
  { path: '/general', element: <GeneralPage /> },
  { path: '/sales-summary', element: <SalesSummaryPage /> },
  { path: '/dashboard-steps', element: <DashboardStepsPage /> },
  { path: '/detail-summary', element: <DetailSummaryPage /> },
  {
    path: '/detail-sales-group',
    element: <DetailSalesGroupPage />,
  },
  {
    path: '/detail-sales-department',
    element: <DetailSalesDepartmentPage />,
  },
  {
    path: '/detail-sales-product',
    element: <DetailSalesProductPage />,
  },
  {
    path: '/detail-sales-package',
    element: <DetailSalesPackagePage />,
  },
  {
    path: '/detail-sales-petty-cash',
    element: <DetailSalesPettyCash />,
  },
  { path: '/void-refund-summary', element: <RefundVoidSummary /> },
  { path: '/void-refund-details', element: <RefundVoidDetails /> },
  { path: '/void-details', element: <DetailVoidReport /> },
  {
    path: '/transaction-list-details',
    element: <TransactionListPage />,
  },
  {
    path: '/transaction-details',
    element: <TransactionDetailPage />,
  },
  {
    path: '/commission-summary',
    element: <CommissionSummary />,
  },
  {
    path: '/commission-details',
    element: <CommissionDetails />,
  },
  { path: '/stock-summary', element: <StockSummaryPage /> },
  {
    path: '/stock-transaction',
    element: <StockTransactionPage />,
  },
  { path: '/stock-audit-list', element: <StockAuditListPage /> },
  {
    path: '/stock-audit-details',
    element: <StockAuditDetailPage />,
  },
];

const DevPath = [
  { path: '/sendmessage', element: <SendPostMessage /> },
  { path: '/listenmessage', element: <ListenPostMessage /> },
  { path: '/dummyfetch', element: <DummyFetch /> },
  { path: '/info', element: <DeployInfo /> },
];

const Routes = () => {
  return (
    <>
      <Suspense fallback={<ReSimpleCircularProgress />}>
        <ReactRoutes>
          <Route path="/" element={<ListPath paths={RoutePaths} />} />
          {RoutePaths.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          {import.meta.env.MODE !== 'production' &&
            DevPath.map((devpath) => (
              <Route
                key={devpath.path}
                path={devpath.path}
                element={devpath.element}
              />
            ))}
        </ReactRoutes>
      </Suspense>
    </>
  );
};

export default Routes;
